import React from 'react'
import { ExpressCheckoutElement, PaymentElement } from '@stripe/react-stripe-js'
import { Box, Checkbox, Flex, HStack, Image, Spacer, Text, VStack } from '@chakra-ui/react'

import { FormikInput } from 'components/elements/input'

import CardChip from 'shared/icons/misc/cardChipGold.png'
import CardArrow from 'shared/icons/misc/CreditCardArrow.svg'

import styles from './styles.module.css'

const PAYMENT_ELEMENT_OPTIONS = {
  terms: {
    applePay: 'never',
    auBecsDebit: 'never',
    bancontact: 'never',
    card: 'never',
    cashapp: 'never',
    googlePay: 'never',
    ideal: 'never',
    paypal: 'never',
    sepaDebit: 'never',
    sofort: 'never',
    usBankAccount: 'never',
  },
  defaultValues: {
    billingDetails: {
      address: {
        country: 'US',
      },
    },
  },
  fields: {
    billingDetails: {
      name: 'never',
      email: 'never',
      address: {
        country: 'never',
        line1: 'auto',
        line2: 'auto',
        city: 'auto',
        state: 'auto',
        postalCode: 'auto',
      },
    },
  },
}

const ErrorMessage = ({ children }) => <div className={styles.row}>{children}</div>

export const StripePayment = ({
  isShowCard = true,
  isLoading,
  formik,
  error,
  setError,
  saveMyDataCheckBox = true,
}) => {
  if (isLoading) {
    return <div className="Result">Processing</div>
  }

  return (
    <>
      {isShowCard && (
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          width="100%"
          background="linear-gradient(180deg, rgba(0, 0, 0, 0) 45.66%, rgba(23, 23, 23, 0.65) 100%), 
        radial-gradient(57.36% 125.58% at 95.53% 6.23%, #4b4b4b 0%, #171717 100%)"
          borderRadius="17px"
          maxWidth="420px"
          padding={{ base: '15px 20px', md: '30px 40px' }}
          aspectRatio="85.6 / 53.98"
          color="white"
          fontWeight="400"
        >
          <Flex justify="space-between" align="flex-start" mb={4}>
            <VStack align="flex-start" spacing={4}>
              <Text
                fontSize={{ base: '16px', md: '20px' }}
                fontWeight="400"
                fontFamily={'Barlow Condensed, sans-serif'}
              >
                Credit Card
              </Text>
              <Image src={CardChip} alt="Card Chip" w={'57px'} h={'42px'} />
            </VStack>
            <Spacer />
          </Flex>

          <HStack
            fontSize={{ base: '28px', md: '32px' }}
            fontWeight="600"
            mb={2}
            pr={4}
            spacing={1.5}
            fontFamily={'Kredit Front, sans-serif'}
          >
            <Text as="span">1234</Text>
            <Spacer />
            <Text as="span">5678</Text>
            <Spacer />
            <Text as="span">9012</Text>
            <Spacer />
            <Text as="span">3456</Text>
          </HStack>

          <Flex justify="space-between" alignItems={'flex-end'} pr={4}>
            <Box>
              <Text
                fontSize={{ base: '16px', md: '20px' }}
                fontWeight="400"
                fontFamily={'Barlow Condensed, sans-serif'}
              >
                Name Surname
              </Text>
            </Box>
            <HStack textAlign="right" mb="6">
              <VStack
                fontSize={{ base: '7px', md: '9px' }}
                lineHeight="0.4"
                fontFamily={'Barlow Condensed, sans-serif'}
              >
                <Text>VALID</Text>
                <Text>THRU</Text>
              </VStack>
              <Image src={CardArrow} alt="Card Icon" mr={2} />

              <Text
                fontSize={{ base: '18px', md: '24px' }}
                fontFamily={'Kredit Front, sans-serif'}
                fontWeight="600"
              >
                01/08
              </Text>
            </HStack>
          </Flex>
        </Flex>
      )}
      <form onSubmit={formik.handleSubmit} className={styles.form}>
        <div className={styles.row}>
          <FormikInput
            placeholder="Email Address"
            label="Email"
            name="email"
            formik={formik}
            color={'white'}
          />
        </div>
        <div className={styles.row}>
          <FormikInput
            placeholder="Name"
            label="Card Holder"
            name="name"
            formik={formik}
            color={'white'}
          />
        </div>
        {error && <ErrorMessage>{error.message}</ErrorMessage>}
        <div className={`${styles.row} ${styles.stripe}`}>
          <PaymentElement
            options={PAYMENT_ELEMENT_OPTIONS}
            id="payment-element"
            onChange={(e) => {
              setError(e.error)
            }}
          />
        </div>
        {/*  Maybe need to delete next block */}
        {saveMyDataCheckBox && (
          <HStack>
            <Text fontSize={'16px'} lineHeight={'20px'} color={'#4F5B76'}>
              Save my data
            </Text>
            <Checkbox
              name="saveData"
              size="lg"
              colorScheme="pink"
              borderColor={'var(--accent)'}
              required={true}
              checked={formik.values.saveData}
              onChange={(event) => {
                formik.setFieldValue('saveData', event.target.checked)
              }}
              sx={{
                'span.chakra-checkbox__control': {
                  borderRadius: '5px',
                },
              }}
            />
          </HStack>
        )}
      </form>
    </>
  )
}

export const GooglePayPayment = ({ onConfirm }) => {
  const expressCheckoutOptions = {
    layout: { maxColumns: 1, maxRows: 3 },
    wallets: { googlePay: 'always', applePay: 'always' },
  }
  return (
    <ExpressCheckoutElement
      options={expressCheckoutOptions}
      onLoadError={(e) => console.log(e)}
      onConfirm={onConfirm}
    />
  )
}
