import React, { useEffect, useMemo, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { isEmpty, omitBy, isNil } from 'lodash'
import { loadStripe } from '@stripe/stripe-js'
import { Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga4'
import {
  VStack,
  Text,
  Box,
  Button,
  Image,
  Flex,
  Divider,
  AbsoluteCenter,
  Stack,
  CircularProgress,
  HStack,
  Grid,
  GridItem,
} from '@chakra-ui/react'

import { Label } from 'components/layout/label'
import RoundArrow from 'shared/icons/misc/roundArrow.svg'
import { CardSkeleton } from 'shared/components'
import { useReadMyUserProfile } from 'shared/queries/userProfile'
import { useIndexMyPaymentMethods } from 'shared/queries/paymentMethod'
import { useCreateSubscriptionMutation } from 'shared/mutations/payment'
import { useCreatePaymentMethodMutation } from 'shared/mutations/paymentMethod'
import { PaymentComplete } from './components/complete/paymentComplete'
import { PaymentFailed } from './components/complete/paymentFailed'
import { GooglePayPayment, StripePayment } from './components/stripe/stripe'
import { SubscriptionLogin } from './components/complete/subscriptionLogin'
import { SubscriptionDisabled } from './components/complete/subscriptionDisabled'
import { environment } from 'environments'
import { useBasketStore } from '../../shared/stores/basketStore'
import { useValidateCouponCode } from '../../shared/queries/coupon'
import ShipStatePage from 'features/registration/stateSelectPage'
import { useIndexServiceConfig } from 'shared/queries/serviceConfig'
import { useUserContextState } from 'shared/contexts/user-context-provider'
import { useIndexMySubscriptions } from 'shared/queries/subscription'
import { useReadMyUser } from 'shared/queries/user'
import { USDollar } from 'shared/utils/currenciesFormatters'
import { useSyncFirstOrderPaymentStatusMutation } from 'shared/mutations/order'
import { useProcessUserActionMutation } from 'shared/mutations/userActions'

const stripePromise = loadStripe(environment.STRIPE_PUBLIC_KEY)

const Subscription = ({
  product,
  profile,
  isProfileLoading,
  totalPrice,
  couponCode,
  couponData,
  isUserSet,
  refetchUserProfile,
}) => {
  const { clear, items: basketItems } = useBasketStore()
  const { isPhoneNumberRequired } = useParams()
  const stripe = useStripe()
  const elements = useElements()
  const [error, setError] = useState(null)
  const [orderId, setOrderId] = useState(null)
  const [isAddCard, setIsAddCard] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [selectedCard, setSelectedCard] = useState(0)
  const [backBTNActive, setBackBTNActive] = useState(false)
  const [showStateSelect, setShowStateSelect] = useState(!isUserSet)

  const [showPaymentComplete, setShowPaymentComplete] = useState(false)
  const [showPaymentError, setShowPaymentError] = useState(false)

  const { data: serviceConfig = [{ availabelStates: [] }] } = useIndexServiceConfig()

  const { data: paymentMethods, isLoading: isPaymentMethodsLoading } = useIndexMyPaymentMethods(
    {
      status: 'ACTIVE',
    },
    isUserSet
  )

  const { mutateAsync: createPaymentMethod } = useCreatePaymentMethodMutation()
  const { mutateAsync: createSubscription, isLoading } = useCreateSubscriptionMutation()
  const { mutateAsync: syncFirstOrderPaymentStatus } = useSyncFirstOrderPaymentStatusMutation()
  const { mutate: processUserAction } = useProcessUserActionMutation()

  const sendPurchaseEvent = ({
    subscriptionData,
    couponData,
    couponCode,
    product,
    totalPrice,
  }) => {
    if (subscriptionData) {
      const discount = couponData?.discount
        ? (product.priceInCents * couponData?.discount) / 100
        : couponData?.discountAmount || 0
      const totalAmountWithDiscount = product.priceInCents - discount
      const price = totalAmountWithDiscount / 100

      const tagManagerArgs = {
        gtmId: environment.GOOGLE_TM_SUCCESS,
        dataLayerName: 'Subscription',
        dataLayer: {
          userId: profile.id,
          productId: product.id,
        },
      }

      const subscriptionEventData = {
        currency: 'USD',
        value: totalPrice / 100,
        transaction_id: subscriptionData?.id,
        coupon: couponCode || null,
        order_id: subscriptionData?.currentOrderId,
        items: [
          {
            item_id: product.id,
            item_name: product.title,
            affiliation: product.medName,
            coupon: couponCode || null,
            discount,
            price,
            quantity: product.amount,
            ndc: product.ndc,
            sku: product.sku,
          },
        ],
      }
      // https://developers.google.com/tag-platform/gtagjs/reference/events#purchase
      ReactGA.event('purchase', subscriptionEventData)
      TagManager.initialize(tagManagerArgs)
    }
  }

  const paymentComplete = ({
    subscriptionData,
    couponData,
    couponCode,
    product,
    totalPrice,
    currentOrderId,
  }) => {
    sendPurchaseEvent({ subscriptionData, couponData, couponCode, product, totalPrice })
    syncFirstOrderPaymentStatus({ orderId: currentOrderId })
    setShowPaymentComplete(true)
    clear()
  }

  const handleSubmit = async (values) => {
    let subscriptionData = null
    if (isAddCard || !isUserSet) {
      if (!stripe || !elements) {
        return
      }
      setProcessing(true)

      if (error) {
        setProcessing(false)
        return
      }

      elements.submit()
      const { error: paymentError, paymentMethod } = await stripe.createPaymentMethod({
        elements,
        params: {
          billing_details: {
            name: values.name,
            email: values.email,
            address: {
              country: 'US',
            },
          },
        },
      })

      if (paymentError) {
        setProcessing(false)
        setError(paymentError)
        return
      }
      const { isPack = false, id, amount, subscriptionRecurringInDays } = product
      const productPayload = {
        amount,
      }
      if (isPack) {
        productPayload.packOfferId = id
      } else {
        productPayload.productId = id
      }

      const paymentParams = {
        items: [productPayload],
        periodInDays: subscriptionRecurringInDays,
      }
      const confirmPaymentParams = {}

      let savedPaymentMethod
      try {
        savedPaymentMethod = await createPaymentMethod(paymentMethod.id)
      } catch (e) {
        setProcessing(false)
        setBackBTNActive(true)
        return
      }

      if (savedPaymentMethod) {
        ReactGA.event('add_payment_info')
      }
      paymentParams.paymentMethodId = savedPaymentMethod.id
      if (couponCode) paymentParams.couponCode = couponCode
      let data
      try {
        data = await createSubscription(paymentParams)
      } catch (e) {
        setError(e.message)
        setShowPaymentError(true)
        return
      }

      subscriptionData = data
      const { clientSecret, currentOrderId } = data
      setOrderId(currentOrderId)
      const { error: confirmError } = await stripe.confirmCardPayment(
        clientSecret,
        confirmPaymentParams
      )
      // https://docs.stripe.com/payments/cash-app-pay/accept-a-payment?web-or-mobile=web&payments-ui-type=elements&client=react
      // const { error: confirmError } = await stripe.confirmPayment({
      //   clientSecret,
      //   // elements,
      //   confirmParams: {
      //     return_url: 'http://localhost:3000/profile/billing',
      //   },
      //   redirect: 'if_required',
      //   // https://docs.stripe.com/js/payment_intents/confirm_payment#confirm_payment_intent-options-redirect
      // })
      // // .then(function(result) {
      // //   if (result.error) {
      // //     setError(result.error.message)
      // //     setShowPaymentError(true)
      // //     clear()
      // //   } else {
      // //     paymentComplete({
      // //       subscriptionData,
      // //       couponData,
      // //       couponCode,
      // //       product,
      // //       totalPrice,
      // //       currentOrderId,
      // //     })
      // //   }
      // // })

      if (confirmError) {
        setError(confirmError.message)
        setShowPaymentError(true)
        clear()
      } else {
        paymentComplete({
          subscriptionData,
          couponData,
          couponCode,
          product,
          totalPrice,
          currentOrderId,
        })
      }

      setProcessing(false)
    } else if (selectedCard > 0) {
      if (!stripe) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return
      }

      setProcessing(true)

      if (error) {
        setProcessing(false)
        return
      }

      const { isPack = false, id, amount, subscriptionRecurringInDays } = product
      const productPayload = {
        amount,
      }
      if (isPack) {
        productPayload.packOfferId = id
      } else {
        productPayload.productId = id
      }

      const savedPaymentMethod = paymentMethods[selectedCard - 1]
      const paymentParams = {
        items: [productPayload],
        periodInDays: subscriptionRecurringInDays,
        paymentMethodId: savedPaymentMethod.id,
      }
      if (couponCode) paymentParams.couponCode = couponCode
      let data
      try {
        data = await createSubscription(paymentParams)
      } catch (e) {
        setError(e.message)
        setShowPaymentError(true)
        return
      }
      if (isEmpty(data)) {
        setError("Couldn't create subscription")
        setShowPaymentError(true)
        return
      }
      subscriptionData = data
      const { clientSecret, currentOrderId } = data
      setOrderId(currentOrderId)
      const { error: confirmError } = await stripe.confirmCardPayment(clientSecret)

      if (confirmError) {
        setError(confirmError.message)
        setShowPaymentError(true)
        clear()
      } else {
        paymentComplete({
          subscriptionData,
          couponData,
          couponCode,
          product,
          totalPrice,
          currentOrderId,
        })
      }
    }
  }

  const handleExpress = async (event) => {
    let subscriptionData = null
    if (!stripe) {
      return
    }

    const { error: submitError } = await elements.submit()
    if (submitError) {
      setError(submitError.message)
      return
    }
    const { billingDetails } = event
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      elements,
      params: {
        billing_details: billingDetails,
      },
    })

    if (error) {
      setError(error.message)
      setProcessing(false)
      return
    }
    const { isPack = false, id, amount, subscriptionRecurringInDays } = product
    const productPayload = {
      amount,
    }
    if (isPack) {
      productPayload.packOfferId = id
    } else {
      productPayload.productId = id
    }

    const confirmPaymentParams = {}
    const savedPaymentMethod = await createPaymentMethod(paymentMethod.id)
    const paymentParams = {
      items: [productPayload],
      periodInDays: subscriptionRecurringInDays,
      paymentMethodId: savedPaymentMethod.id,
    }
    if (couponCode) paymentParams.couponCode = couponCode
    let data
    try {
      data = await createSubscription(paymentParams)
    } catch (e) {
      setError(e.message)
      setShowPaymentError(true)
      return
    }
    subscriptionData = data
    const { clientSecret, currentOrderId } = data
    setOrderId(currentOrderId)
    const { error: confirmError } = await stripe.confirmCardPayment(
      clientSecret,
      confirmPaymentParams
    )

    if (confirmError) {
      setError(confirmError.message)
      setShowPaymentError(true)
      clear()
    } else {
      paymentComplete({
        subscriptionData,
        couponData,
        couponCode,
        product,
        totalPrice,
        currentOrderId,
      })
    }
    setProcessing(false)
  }

  const cardSchema = Yup.object({
    name: Yup.string()
      .required('Card Holder name is required')
      .max(20, 'Name cannot be longer than 20 characters'),
    email: Yup.string().email('Invalid email').required('Email Address is required'),
  })
  const formik = useFormik({
    initialValues: {
      name: profile ? `${profile.firstName} ${profile.lastName}` : '',
      email: profile?.email || '',
      saveData: false, // Maybe need to delete
    },
    validationSchema: cardSchema,
    onSubmit: handleSubmit,
  })
  useEffect(() => {
    if (!isProfileLoading && !isEmpty(profile)) {
      formik.setFieldValue('name', `${profile.firstName} ${profile.lastName}`)
      formik.setFieldValue('email', profile?.email)
    }
  }, [profile, isProfileLoading])

  useEffect(() => {
    localStorage.setItem('IS_USER_LOGIN', true)

    let discountAmount = 0

    if (couponData?.discount) {
      discountAmount = (product.priceInCents * (1 - couponData?.discount / 100)) / 100
    }
    if (couponData?.discountAmount) {
      discountAmount = couponData?.discountAmount / 100
    }

    const checkoutEventData = {
      currency: 'USD',
      value: totalPrice / 100,
      coupon: couponData?.referenceId || null,
      items: [
        {
          item_id: product.id,
          item_name: product.title,
          affiliation: product.medName,
          coupon: couponData?.referenceId || null,
          discount: discountAmount,
          price: product.priceInCents / 100,
          quantity: product.amount,
          ndc: product.ndc,
          sku: product.sku,
        },
      ],
    }

    //https://developers.google.com/tag-platform/gtagjs/reference/events#begin_checkout
    ReactGA.event('begin_checkout', checkoutEventData)

    let event = {
      eventName: 'CHECKOUT_STARTED',
      productId: product.id,
      couponId: couponData?.id,
    }

    processUserAction(omitBy(event, isNil))
  }, [])

  const readyToConfirm =
    (selectedCard === 0 && isAddCard && formik.isValid) ||
    (!isUserSet && formik.isValid) ||
    selectedCard > 0

  const productsCount = useMemo(() => {
    return basketItems.reduce((a, b) => {
      a = a + b.amount
      return a
    }, 0)
  }, [basketItems])

  const moreThanOneSelected = productsCount > 1
  const isBlockedState =
    !!profile &&
    profile.address?.addressRegion &&
    !serviceConfig[0].availabelStates?.includes(profile.address.addressRegion)

  if (isPaymentMethodsLoading) {
    return (
      <Flex w={'100%'} alignItems={'center'} justifyContent={'center'} py="9%" px="8%">
        <CircularProgress isIndeterminate color="var(--accent)" />
      </Flex>
    )
  }
  if (showStateSelect) {
    return (
      <ShipStatePage
        setShowStateSelect={setShowStateSelect}
        availabelStates={serviceConfig[0].availabelStates}
        refetchUserProfile={refetchUserProfile}
      />
    )
  }

  if (showPaymentComplete) {
    window.location.hash = 'SuccessPayment'
    return (
      <PaymentComplete
        orderId={orderId}
        userId={profile.id}
        profile={profile}
        totalPrice={totalPrice}
        product={product}
        isPhoneNumberRequired={isPhoneNumberRequired === 'true'}
      />
    )
  }

  if (showPaymentError) {
    return (
      <PaymentFailed message={error} isPhoneNumberRequired={isPhoneNumberRequired === 'true'} />
    )
  }

  return (
    <Box py="7%" px="8%" mb="60px">
      <VStack align="center" spacing={4}>
        <Label>Payment Details</Label>
      </VStack>

      <Grid templateColumns={{ base: '1fr', lg: '1fr 1fr' }} gap="1rem" mt={8}>
        <GridItem order={{ base: 2, lg: 0 }}>
          <Flex w="100%" justifyContent={'center'}>
            <VStack maxW={'400px'} align="center">
              {!isUserSet && (
                <Text
                  color={'var(--accent)'}
                  w={'100%'}
                  pt={4}
                  maxW={'400px'}
                  textAlign={'start'}
                >
                  Thank you. We’re able to prescribe to your state.
                </Text>
              )}
              <Text my={6} textAlign={'start'}>
                Please enter your payment details below and together we can start your weight
                loss journey.
              </Text>
              <Box m={4} width="100%">
                <GooglePayPayment
                  stripe={stripe}
                  amount={totalPrice}
                  onConfirm={handleExpress}
                />
              </Box>
              <Box width="100%">
                <Box position="relative" width="100%" py="1" mt="24px">
                  <Divider
                    orientation="horizontal"
                    variant="dashed"
                    borderColor="black"
                    border={'1px'}
                  />
                  <AbsoluteCenter bg={'var(--main-bg)'} px="2">
                    or
                  </AbsoluteCenter>
                </Box>
                {!isUserSet && (
                  <StripePayment
                    isShowCard={false}
                    isLoading={isLoading}
                    formik={formik}
                    error={error}
                    setError={setError}
                    saveMyDataCheckBox={false}
                  />
                )}
                {isUserSet && isAddCard && (
                  <Box w={'100%'} mt={'10'}>
                    <StripePayment
                      isShowCard={true}
                      isLoading={isLoading}
                      formik={formik}
                      error={error}
                      setError={setError}
                      saveMyDataCheckBox={false}
                    />
                  </Box>
                )}
                {isUserSet && !isAddCard && (
                  <VStack gap={4} maxW="440px" mx="auto" mt={'10'}>
                    <Box position="relative" w="100%">
                      <Box
                        borderRadius="43px"
                        bg="black"
                        aspectRatio="85.60/53.98"
                        w="100%"
                        position="relative"
                        zIndex={4}
                      >
                        {selectedCard === 0 ? (
                          <CardSkeleton totalPriceInCents={totalPrice} />
                        ) : (
                          <CardSkeleton
                            totalPriceInCents={totalPrice}
                            mask={paymentMethods[selectedCard - 1]?.cardNumberMask}
                            saved={true}
                            brand={paymentMethods[selectedCard - 1]?.brand}
                            expMonth={paymentMethods[selectedCard - 1]?.expMonth}
                            expYear={paymentMethods[selectedCard - 1]?.expYear}
                          />
                        )}
                      </Box>
                      <Box
                        borderRadius="43px"
                        position="absolute"
                        zIndex={3}
                        bg="gray.400"
                        w="calc(100% - 10px)"
                        h="86%"
                        top="50%"
                        right="-10px"
                        transform="translateY(-50%)"
                      />
                      <Box
                        borderRadius="43px"
                        position="absolute"
                        zIndex={2}
                        bg="gray.300"
                        w="calc(100% - 20px)"
                        h="70%"
                        top="50%"
                        right="-20px"
                        transform="translateY(-50%)"
                      />
                    </Box>

                    <HStack justifyContent="center" spacing={4}>
                      {selectedCard > 0 && (
                        <Image
                          src={RoundArrow}
                          alt="Prev"
                          cursor="pointer"
                          transform="rotate(180deg)"
                          onClick={() => setSelectedCard((prevState) => prevState - 1)}
                        />
                      )}
                      <Text>Switch Payment Method</Text>
                      {paymentMethods?.length > selectedCard && (
                        <Image
                          src={RoundArrow}
                          alt="Next"
                          cursor="pointer"
                          onClick={() => setSelectedCard((prevState) => prevState + 1)}
                        />
                      )}
                    </HStack>
                  </VStack>
                )}

                {isUserSet && (
                  <HStack w={'100%'} my={'4'} spacing={'4'} justifyContent={'center'}>
                    <Button
                      colorScheme="pink"
                      bg="var(--accent)"
                      width="160px"
                      borderRadius="10px"
                      fontWeight="600"
                      onClick={() => {
                        setIsAddCard(true)
                        setBackBTNActive(true)
                      }}
                      isDisabled={selectedCard !== 0 || backBTNActive}
                    >
                      Add Card
                    </Button>

                    <Button
                      bg="var(--accent)"
                      width="160px"
                      borderRadius="10px"
                      fontWeight="600"
                      onClick={() => {
                        setIsAddCard(false)
                        setProcessing(false)
                        setBackBTNActive(false)
                      }}
                      isDisabled={!backBTNActive}
                      colorScheme="pink"
                    >
                      Back
                    </Button>
                  </HStack>
                )}
              </Box>

              <Button
                isDisabled={!!error || !readyToConfirm || processing || isBlockedState}
                onClick={() => handleSubmit(formik.values)}
                colorScheme="blackAlpha"
                bg="black"
                isLoading={processing}
                loadingText="Processing"
                width="100%"
                _hover={{ bg: '#333' }}
                _disabled={{ opacity: 0.6 }}
              >
                Pay USD {USDollar.format(totalPrice / 100)}
              </Button>

              <Text fontSize="xs" textAlign="end" mt={1} width="100%">
                Powered by <span style={{ fontWeight: 'bold', fontSize: '20px' }}>stripe</span>
              </Text>

              {couponData && (
                <Stack alignItems="center" w="100%">
                  <Text fontSize="18px" color="var(--gray-text)">
                    Coupon code{' '}
                    <Text as="span" fontWeight={'600'}>
                      &apos;{couponData.referenceId}&apos;
                    </Text>{' '}
                    was applied,
                    <br />
                    the discount is{' '}
                    <Text as="span" fontWeight={'600'}>
                      {couponData.discount
                        ? `${couponData.discount}%`
                        : `${USDollar.format(couponData.discountAmount / 100)}`}
                    </Text>{' '}
                    included.
                  </Text>
                </Stack>
              )}
              {!couponData && profile?.discount && (
                <Stack alignItems="center" w="100%">
                  <Text fontSize="18px" color="var(--gray-text)">
                    Referral coupon was applied,
                    <br />
                    the discount is{' '}
                    <Text as="span" fontWeight={'600'}>
                      {profile?.discount}%
                    </Text>
                    .
                  </Text>
                </Stack>
              )}
              {isBlockedState && (
                <Stack alignItems="end" w="100%">
                  <Text color="red">
                    Service temporary unavailable for your state, we will notify you when its
                    will be updated.
                  </Text>
                </Stack>
              )}
              {moreThanOneSelected && (
                <Stack alignItems="end" w="100%">
                  <Text color="red">
                    You can&apos;t create subscription for more that one product.
                  </Text>
                  <Text color="red">Please remove extra products from your basket.</Text>
                </Stack>
              )}
            </VStack>
          </Flex>
        </GridItem>

        <GridItem order={{ base: 1, lg: 0 }}>
          <VStack alignItems={{ base: 'center', lg: 'flex-start' }}>
            <Box maxW={{ base: '400px', lg: '460px' }}>
              <Text
                w={'100%'}
                fontSize={'18px'}
                fontWeight="bold"
                textAlign={'start'}
                mb={'4'}
                color={'#600F85'}
              >
                Your Basket:
              </Text>
              <Flex
                gap={5}
                wrap={{ base: 'wrap', sm: 'nowrap' }}
                p={'26px'}
                borderRadius="16px"
                bgColor={'#FFFFFF'}
              >
                <Flex
                  background="linear-gradient(0deg, rgba(230, 224, 242, 0.8) 0%, rgba(198, 183, 230, 0.8) 100%)"
                  borderRadius="10px"
                  width={{ base: '100%', sm: '60%' }}
                  alignItems={'center'}
                  justifyContent={'center'}
                  p={'2'}
                >
                  <Image src={product.imageUrl} alt={product.title} />
                </Flex>
                <VStack
                  width={{ base: '100%', sm: '40%' }}
                  alignItems={'flex-start'}
                  spacing={'3'}
                >
                  <Text
                    color="var(--color-ui-2, #1B2028)"
                    fontSize={'22px'}
                    lineHeight={'22px'}
                    fontWeight="bold"
                  >
                    {product.title}
                  </Text>
                  {product.productDetails && (
                    <Box width="100%" textAlign={'start'}>
                      <Text
                        color="var(--accent)"
                        fontSize={{ base: '12px', xl: '14px' }}
                        fontWeight="bold"
                      >
                        Product Details
                      </Text>
                      <Text
                        fontSize={{ base: '12px', xl: '14px' }}
                        lineHeight={{ base: '12px', xl: '14px' }}
                      >
                        {product.productDetails}
                      </Text>
                    </Box>
                  )}
                  <Box width="100%" textAlign={'start'}>
                    <Text
                      color="var(--color-ui-2, #1B2028)"
                      fontSize={'12px'}
                      lineHeight={'12px'}
                      fontWeight="bold"
                    >
                      Introductory Offer:
                    </Text>
                    <Text
                      color="var(--accent)"
                      fontSize={'36px'}
                      lineHeight={'36px'}
                      fontWeight="bold"
                    >
                      {USDollar.format(totalPrice / 100)}
                    </Text>
                    {couponData && (
                      <Text
                        color="var(--accent-hover)"
                        fontSize={{ base: '8px', xl: '10px' }}
                        lineHeight={{ base: '8px', xl: '10px' }}
                        fontWeight={'450'}
                      >
                        and then {USDollar.format(product.priceInCents / 100)} per month
                      </Text>
                    )}
                  </Box>
                </VStack>
              </Flex>
            </Box>
          </VStack>
        </GridItem>
      </Grid>
    </Box>
  )
}

export const SubscriptionPage = () => {
  const { user } = useUserContextState()
  const registrationToken = localStorage.getItem('REGISTRATION_TOKEN')
  const authToken = localStorage.getItem('AUTH_TOKEN')

  const {
    data: profile,
    isLoading: isProfileLoading,
    refetch: refetchUserProfile,
  } = useReadMyUserProfile({
    includeDiscount: true,
  })

  const { data: myUser, isLoading: isUserLoading } = useReadMyUser({}, !!authToken)

  const isUserSet = !isEmpty(myUser) || !isEmpty(user) || !!authToken

  const { data: subscriptions = [], isLoading: isSubscriptionsLoading } =
    useIndexMySubscriptions({ statuses: ['ACTIVE', 'PENDING'] }, isUserSet)
  const { getItem } = useBasketStore()
  const { id: productId } = useParams()
  const [params] = useSearchParams()

  const product = useMemo(() => getItem(productId), [productId])

  const couponCode =
    params.get('couponCode') ||
    product?.customCoupon?.referenceId ||
    product?.defaultCoupon?.referenceId ||
    null

  const { data: couponData, isLoading: isCouponLoading } = useValidateCouponCode(
    { code: couponCode, productIds: [productId] },
    !!couponCode
  )

  if (isSubscriptionsLoading || isUserLoading || isCouponLoading || isProfileLoading) {
    return (
      <Flex w={'100%'} alignItems={'center'} justifyContent={'center'} py="9%" px="8%">
        <CircularProgress isIndeterminate color="var(--accent)" />
      </Flex>
    )
  }

  if (!authToken && !registrationToken) {
    return <SubscriptionLogin />
  }

  if (!isEmpty(subscriptions)) {
    window.location.hash = 'SubscriptionDisabled'
    return <SubscriptionDisabled />
  }

  let { totalPrice } = product

  if (!isEmpty(couponData)) {
    const { discount, discountAmount: discountAmountInCents } = couponData
    const discountAmount = discount ? (totalPrice * discount) / 100 : discountAmountInCents
    totalPrice = Number((totalPrice - discountAmount).toFixed(2))
  }

  if (isEmpty(couponData) && profile?.discount) {
    const discountAmount = (totalPrice * profile.discount) / 100
    totalPrice = Number((totalPrice - discountAmount).toFixed(2))
  }

  const options = {
    mode: 'subscription',
    amount: totalPrice,
    currency: 'usd',
    paymentMethodCreation: 'manual',
    appearance: {
      variables: {
        borderRadius: '10px',
        fontFamily: 'futura-pt, sans-serif',
        spacingUnit: '4px',
      },
      rules: {
        '.Label': {
          color: '#4f5b76',
          fontSize: '17px',
          fontWeight: '450',
          lineHeight: 'normal',
          fontFamily: 'futura-pt, sans-serif',
        },
        '.Input': {
          border: '2.5px solid #e0e0e0',
          boxShadow: '0px 2.51px 5.01px 0px rgba(0, 0, 0, 0.12)',
          borderRadius: '10px',
          fontSize: '16px',
          marginBottom: '4px',
        },

        '.Input:focus': {
          borderColor: '2.5px solid #e0e0e0',
          boxShadow: '0px 2.51px 5.01px 0px rgba(0, 0, 0, 0.12)',
        },
      },
    },
  }

  return (
    <Elements stripe={stripePromise} options={options}>
      <Subscription
        product={product}
        profile={profile}
        isProfileLoading={isProfileLoading}
        totalPrice={totalPrice}
        couponCode={couponCode}
        couponData={couponData}
        isUserSet={isUserSet}
        refetchUserProfile={refetchUserProfile}
      />
    </Elements>
  )
}
