import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import {
  Box,
  Button,
  Link as ChakraLink,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useMediaQuery,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { useFormik } from 'formik'
import { isEmpty, size, trimStart } from 'lodash'
import * as Yup from 'yup'
import 'yup-phone'

import { Label } from 'components/layout/label'
import { useRegisterConsumerUserMutation } from 'shared/mutations/user'
import { APP_PATHS } from 'paths'

import { InitialStep } from './steps/InitialStep'

const RegistrationIncompleteModal = ({ isOpen, onClose }) => {
  const handleClose = () => {
    onClose()
    navigate(APP_PATHS.sentVerifyEmail)
  }

  const navigate = useNavigate()
  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      returnFocusOnClose={false}
      autoFocus={false}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent borderRadius={'16px'}>
        <ModalHeader px={'46px'}>
          <Box display="flex" alignItems="center" justifyContent={'center'}>
            <Text variant="h2" color="var(--accent)" mt={'20px'} fontSize={'24px'}>
              Email Verification Sent
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody fontSize={'16px'} lineHeight={'18px'} px={'46px'} textAlign={'center'}>
          <Text mb={2}>
            <Text as="span" fontWeight="bold">
              ACTION REQUIRED:
            </Text>{' '}
            Please check your email for a verification message from{' '}
            <Text
              as="span"
              color="var(--help-color)"
              fontWeight="bold"
              textDecoration="underline"
            >
              info@maion.ai
            </Text>
          </Text>
          <Text as="i" fontWeight="bold">
            (don’t forget to check your SPAM folder too)
          </Text>
        </ModalBody>
        <ModalFooter p="18px">
          <Button onClick={() => navigate(APP_PATHS.registration)} colorScheme="pink">
            Ok
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export const RegistrationPage = () => {
  const toast = useToast()
  const [params] = useSearchParams()
  const referralToken = params.get('referralToken')
  let savedReferralToken = localStorage.getItem('REFERRAL_TOKEN')
  const toBuy = params.get('toBuy')
  const [isMobile] = useMediaQuery('(max-width: 800px)')
  const [fieldErrors, setFieldErrors] = useState({})
  const { onOpen, onClose, isOpen } = useDisclosure()
  const { mutate: onRegister } = useRegisterConsumerUserMutation({
    onSuccess: (data) => {
      localStorage.removeItem('REFERRAL_TOKEN')
      localStorage.setItem('USER_ID', data.id)
      clareForm()
      onOpen()
    },
    onError: ({ error }) => {
      const { code, fields, message, context } = error

      if (code === 'COM-9') {
        let validationErrors = {}
        fields.forEach(({ dataPath, message }) => {
          validationErrors[trimStart(dataPath, '.')] = message
        })
        setFieldErrors(validationErrors)
      } else if (code === 'MNC-1-1') {
        let errors = {}
        if (context?.email) {
          errors.email = 'User with such email already exists.'
        }
        setFieldErrors(errors)
      } else if (code === 'MNC-12') {
        setFieldErrors({
          birthDate: message,
        })
      }
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
  })

  useEffect(() => {
    if (referralToken && !savedReferralToken) {
      localStorage.setItem('REFERRAL_TOKEN', referralToken)
      savedReferralToken = referralToken
    }
  }, [])

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
      firstName: '',
      lastName: '',
      acceptedTermsVersion: 0,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('Legal First Name is required'),
      lastName: Yup.string().required('Legal Last Name is required'),
      email: Yup.string().email('Invalid email').required('Email Address is required'),
      password: Yup.string()
        .required('Please Enter your new password')
        .test('test-regex-groups', 'Bad password', (value) => {
          if (!value) return false

          const regexs = [
            { regex: /(?=.*\d)/, message: 'One Number' },
            { regex: /(?=.*[a-z])/, message: 'One Lowercase' },
            { regex: /(?=.*[A-Z])/, message: 'One Uppercase' },
            {
              regex: /(?=.*[`~!@#$%^&*()\-_=+[{}\]|;:'",<.>/?€£¥₹])/,
              message: 'One Special Case Character',
            },
          ]
          let messages = []
          if (size(value) < 9) {
            messages.push('9 Characters')
          }
          regexs.map(({ regex, message }) => {
            const matches = regex.exec(value)
            if (!matches) messages.push(message)
          })
          if (!isEmpty(messages)) {
            return new Yup.ValidationError(
              'Must Contain ' + messages.join(', '),
              value,
              'password'
            )
          }
          return true
        }),
      confirmPassword: Yup.string()
        .required('Please confirm your password')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
      acceptedTermsVersion: Yup.boolean().oneOf(
        [true],
        'You must agree to the terms and conditions'
      ),
    }),
    onSubmit: (values) => {
      onRegister({
        ...values,
        ...(!!savedReferralToken && { referralToken: savedReferralToken }),
      })
    },
  })

  const clareForm = () => {
    formik.resetForm()
  }

  return (
    <>
      <RegistrationIncompleteModal isOpen={isOpen} onClose={onClose} />
      <Box
        as="form"
        onSubmit={formik.handleSubmit}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="flex-start"
        gap="30px"
        padding="7% 8% 60px 8%"
      >
        <Label>Your Details</Label>
        <VStack
          width={isMobile ? '100%' : '450px'}
          alignItems="flex-start"
          spacing={4}
          textAlign="start"
          fontSize="14px"
          lineHeight={'normal'}
          fontWeight={'400'}
        >
          <Text mb={6}>
            Thank you for the interest in our product. Before we can confirm your order please
            give us a few of your details.
          </Text>

          <InitialStep formik={formik} fieldErrors={fieldErrors} />
        </VStack>

        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          alignItems="center"
          justifyContent={isMobile ? 'center' : 'flex-end'}
          gap="20px"
          width={isMobile ? '100%' : '450px'}
        >
          <Text fontSize="22px">
            If you have an account please{' '}
            <ChakraLink
              color={'var(--help-color)'}
              as={Link}
              to={`${APP_PATHS.registration}${toBuy ? '?toBuy=1' : ''}`}
              fontWeight={'semibold'}
              textDecoration={'underline'}
            >
              sign in
            </ChakraLink>
          </Text>
          <Button
            type="submit"
            colorScheme="pink"
            bg={'var(--accent)'}
            width={isMobile ? '50%' : '160px'}
            borderRadius="8px"
            isDisabled={!isEmpty(formik.errors) || !formik.dirty}
            mr={isMobile ? '0' : '-180px'}
          >
            Confirm
          </Button>
        </Box>
      </Box>
    </>
  )
}

RegistrationPage.propTypes = {
  isFromScheduler: PropTypes.bool,
}
